<template>
  <b-card>
    <b-card-title class="mb-1">
      {{ titulo }}
    </b-card-title>
    <b-card-sub-title class="mb-2">
      {{ descricao }}
    </b-card-sub-title>
  
    <vue-apex-charts
        ref="apexDonutChart"
        type="donut"
        height="350"
        :options="options"
        :series="series"
    />
  </b-card>
</template>

<script>
import { BCard, BCardSubTitle, BCardTitle, } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    titulo: {
      type: String,
      default: '',
    },
    descricao: {
      type: String,
      default: '',
    },
    series: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      apexChatData,
    }
  },
  watch: {
    series: {
      handler() {
        this.$nextTick(() => {
          if (this.$refs.apexDonutChart) {
            this.$refs.apexDonutChart.refresh()
          }
        })

      },
      deep: true,
    },
    options: {
      handler() {
        this.$nextTick(() => {
          if (this.$refs.apexDonutChart) {
            this.$refs.apexDonutChart.refresh()
          }
        })

      },
      deep: true,
    },
  },
}
</script>
