import axios from '@axios'

const baseUrl = 'dashboard'
export default {
  namespaced: true,
  state: {
    paginacao: {},
    modosExibicao: [],
  },
  getters: {},
  mutations: {},
  actions: {
    getTotalClientes(ctx, payload) {
      return new Promise((resolve, reject) => {
        const urlPath = `${baseUrl}/clientes/total`
        axios
          .get(urlPath, { params: payload })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getTotalObras(ctx, payload) {
      return new Promise((resolve, reject) => {
        const urlPath = `${baseUrl}/obras/total`
        axios
          .get(urlPath, { params: payload })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getTotalRegistros(ctx, payload) {
      return new Promise((resolve, reject) => {
        const urlPath = `${baseUrl}/registros/total`
        axios
          .get(urlPath, { params: payload })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getTotalAtividades(ctx, payload) {
      return new Promise((resolve, reject) => {
        const urlPath = `${baseUrl}/atividades/total`
        axios
          .get(urlPath, { params: payload })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getTotalProjetos(ctx, payload) {
      return new Promise((resolve, reject) => {
        const urlPath = `${baseUrl}/projetos/total`
        axios
          .get(urlPath, { params: payload })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getTotalClientesComProjetos(ctx, payload) {
      return new Promise((resolve, reject) => {
        const urlPath = `${baseUrl}/clientes/total-com-projetos`
        axios
          .get(urlPath, { params: payload })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getTotalProjetosPorDisciplina(ctx, payload) {
      return new Promise((resolve, reject) => {
        const urlPath = `${baseUrl}/projetos/total-por-disciplinas`
        axios
          .get(urlPath, { params: payload })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
