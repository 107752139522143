<template>
  <b-sidebar id="add-new-sidebar" :visible="isAddNewSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow
             backdrop no-header right @hidden="resetForm"
             @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titulo }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <!-- Razão Social -->

          <validation-provider #default="validationContext" name="Nome da Subdisciplina" rules="required">
            <b-form-group label="Nome do Subdisciplina" label-for="nome-subdisciplina">
              <b-form-input id="nome-subdisciplina" name="subdisciplina" v-model="itemData.nome" autofocus
                            :state="getValidationState(validationContext)" trim
                            placeholder="Informe o nome da subdisciplina"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mr-2" type="submit">
              Salvar
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                      @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton,
  BForm,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BSidebar
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,
    BFormCheckbox,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    titulo: {
      type: String,
      required: true,
    },

  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      nome: '',
    }

    // Use toast
    const toast = useToast()

    const itemData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {

      store.dispatch('app-subdisciplinas/store', itemData.value)
          .then((respo) => {
            emit('refetch-data')
            emit('update:is-add-new-sidebar-active', false)
            toast({
              component: ToastificationContent,
              props: {
                title: 'Subdisciplina adicionada com sucesso',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            if (error.response && error.response.status === 422) {
              let listaErros = error.response.data.errors
              for (let field in listaErros) {
                if (listaErros.hasOwnProperty(field)) {
                  const errorMessages = listaErros[field]
                  errorMessages.forEach(errorMessage => {
                    toast({
                      component: ToastificationContent,
                      props: {
                        title: errorMessage,
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                      },
                    })
                  })

                }
              }
            }
          })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    return {
      itemData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

