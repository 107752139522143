import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useSubdisciplinasList() {
  // Use toast
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  let tableColumns = [
    {
      key: 'nome',
      sortable: true,
    },
    {
      key: 'disciplinas',
    },
    { key: 'formatos' },
    {
      key: 'tarefas',
      label: 'Grup. | Taref.',
      class: 'text-center',
    },

  ]

  if (ability.can('valor-comercial', 'subdisciplinas')) {
    tableColumns.push({
      key: 'valor',
      label: 'Valor BIM | CAD',
      class: 'text-center',
    })
  }
  tableColumns.push({
    key: 'Ações',
    class: 'text-center',
  })
  const perPage = ref(10)
  const totalItens = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    currentPage.value - 1

    const localItemsCount = refListTable.value
      ? refListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItens.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], (newVal, oldVal) => {
    refetchData()
  })

  const fetchDados = (ctx, callback) => {
    store
      .dispatch('app-subdisciplinas/fetchDados', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        callback(response.data)
        totalItens.value = response.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao listar as subdisciplinas',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const update = item => {
    store
      .dispatch('app-subdisciplinas/update', item)
      .then(() => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: 'Subdisciplina editada com sucesso',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response?.data?.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const deleteItem = id => {
    store.dispatch('app-subdisciplinas/deleteItem', id)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Subdisciplina excluído com sucesso',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        refetchData()
      })
  }

  return {
    fetchDados,
    update,
    deleteItem,
    tableColumns,
    perPage,
    currentPage,
    totalItens,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    refetchData,
  }
}
