import { computed, ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useItemList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'nome',
      label: 'Descrição',
      sortable: true,
    },
    {
      key: 'abreviacao',
      label: 'Abreviação',
      sortable: true,
    },
    { key: 'Ações', class: 'text-center' },
  ]
  const perPage = ref(10)
  const totalItens = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    // eslint-disable-next-line no-unused-expressions
    currentPage.value - 1

    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItens.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], (newVal, oldVal) => {
    refetchData()
  })

  const fetchDados = (ctx, callback) => {
    store
      .dispatch('etapa-projeto/fetchDados', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        callback(response.data)
        totalItens.value = response.data.totalItens
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao criar a etapa de projeto',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteItem = (id) => {
    store.dispatch('etapa-projeto/deleteItem', id).then(() => {
      refetchData()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Etapa de projeto excluído com sucesso',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    })
  }
  const update = (item) => {
    store.dispatch('etapa-projeto/update', item).then(() => {
      refetchData()

      toast({
        component: ToastificationContent,
        props: {
          title: 'Etapa de projeto editado com sucesso ',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    })
  }

  return {
    fetchDados,
    deleteItem,
    tableColumns,
    perPage,
    currentPage,
    totalItens,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    refetchData,
    update,
  }
}
