import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,
    registro: {},
  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },
  },
  actions: {
    fetchDados({ commit }, queryParams) {
      commit('alteraLoading', true)
      return new Promise((resolve) => {
        axios
          .get('/etapas', { params: queryParams })
          .then((response) => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch((error) => console.log(error))
      })
    },

    show(ctx, idTipoRegistro) {
      return new Promise((resolve, reject) => {
        axios
          .get(`etapas/${idTipoRegistro}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },

    store(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/etapas', itemData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },

    update(ctx, itemData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/etapas/${itemData.uuid}`, itemData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },

    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/etapas/${id}`)
          .then(() => resolve())
          .catch((error) => reject(error))
      })
    },
  },
}
