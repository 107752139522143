<template>
  <div>
    <form-filters
      :is-filters-sidebar-active.sync="isFiltersSidebarActive"
      titulo="Filtros"
      @aplicar-filtros="tratarEventoAplicarFiltros"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <h5 class="card-title pt-2 pl-2 pb-0 mb-0">Lista de Propostas</h5>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibição</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Pesquisar..."
              />
              <b-button
                class="mr-1"
                variant="primary"
                @click="isFiltersSidebarActive = true"
              >
                <span class="text-nowrap">
                  Filtros ({{ quantidadeFiltrosAplicados }})
                </span>
              </b-button>
              <b-button variant="success" :to="{ name: 'propostas-create' }">
                <span class="text-nowrap"> Adicionar </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative font-size-9"
        :items="fetchDados"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum resultado encontrado"
        :sort-desc.sync="isSortDirDesc"
        :busy="isLoad"
        striped
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> Carregando...</strong>
          </div>
        </template>
        <template #cell(codigo_proposta)="data">
          <div class="column__w250">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  v-if="data.item.cliente"
                  size="4.5em"
                  :src="data.item.cliente.imagem"
                  :text="avatarText(data.item.cliente.razao_social)"
                  :variant="'secondary'"
                  class="avatar-with-shadow"
                />
                <b-avatar
                  v-else
                  size="4.5em"
                  src=""
                  :text="avatarText(data.item.lead.razao_social)"
                  :variant="'secondary'"
                  class="avatar-with-shadow"
                >
                </b-avatar>
              </template>
              <div class="font-weight-bold">
                {{ data.item.codigo_proposta }}
              </div>
              <div
                class="font-size-7"
                v-if="data.item.cliente && data.item.cliente.razao_social"
              >
                {{ data.item.cliente.razao_social }}
              </div>
              <div
                class="font-size-7"
                v-if="data.item.lead && data.item.lead.razao_social"
              >
                {{ data.item.lead.razao_social }}
              </div>
              <div class="font-size-8 text-primary">
                <strong>SOLICITADO: </strong
                >{{ moment(data.item.data_solicitacao).format('DD/MM/YYYY') }}
              </div>
            </b-media>
          </div>
        </template>

        <template #cell(disciplinas)="data">
          <div class="displinasProjetos">
            <tag-color
              v-for="(item, index) in data.item.disciplinas"
              :key="index"
              v-b-tooltip.hover.top.v-primary
              class="mb-1 cursor"
              :texto="item.abreviacao"
              :title="item.nome"
              :bg-color="item.bg_color"
            />
          </div>
        </template>

        <template #cell(status)="data">
          <div class="displinasProjetos">
            <tag-color
              v-b-tooltip.hover.top.v-primary
              class="mb-1 cursor"
              :texto="data.item.status.abreviacao"
              :title="data.item.status.descricao"
              :bg-color="'#336195'"
            />
          </div>
        </template>

        <template #cell(data_entrega)="data">
          <div>
            <small>{{
              moment(data.item.data_entrega).format('DD/MM/YYYY')
            }}</small>
          </div>
        </template>
        <template #cell(valor)="data">
          <div>
            <small>{{ formatarParaMoedaBrasileira(data.item.valor) }}</small>
          </div>
        </template>
        <template #cell(tipo_custo)="data">
          <div class="font-size-8 text-primary">
            <strong>Tipo: </strong>{{ data.item.tipo_custo }}
          </div>
          <div class="font-size-8 text-primary">
            <strong>Qtd Subs: </strong>{{ data.item.subdisciplinas_count }}
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(ações)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{
                name: 'propostas-visualizar',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Visualizar</span>
            </b-dropdown-item>

            <b-dropdown-item
              :to="{ name: 'propostas-editar', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="modalExcluir(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} dos
              {{ dataMeta.of }} resultados encontrados</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItens"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <modalConfirmacao
      id="modal-confirmacao"
      titulo="Excluir Item"
      :item-id="dadosItem.id"
      :item-nome="dadosItem.nome"
      @itemConfirmado="excluirItem"
      @click="modalExcluir(dadosItem)"
    />
  </div>
</template>

<script>
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BMedia,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
import store from '@/store'
import vSelect from 'vue-select'
import { avatarText, formatarParaMoedaBrasileira } from '@core/utils/filter'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import FormFilters from './FormFilters.vue'
import registroStoreModule from '../propostaStoreModule'
import useItemList from './useItemList'
import TagColor from '@/views/components/componentesPensou/TagColor.vue'
import moment from 'moment-timezone'

export default {
  components: {
    TagColor,
    BMedia,
    BAvatar,
    BFormGroup,
    SelectComPesquisa,
    VBTooltip,
    FormFilters,
    modalConfirmacao,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  setup() {
    const ITEM_APP_STORE_MODULE_NAME = 'app-propostas'

    // Register module
    if (!store.hasModule(ITEM_APP_STORE_MODULE_NAME))
      store.registerModule(ITEM_APP_STORE_MODULE_NAME, registroStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ITEM_APP_STORE_MODULE_NAME))
        store.unregisterModule(ITEM_APP_STORE_MODULE_NAME)
    })

    const {
      fetchDados,
      fetchUsuarios,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      filters,
      refUserListTable,
      refetchData,
      deleteItem,
    } = useItemList()

    return {
      deleteItem,
      fetchDados,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      filters,
      refUserListTable,
      refetchData,
      fetchUsuarios,
      avatarText,
      moment,
    }
  },
  data() {
    return {
      isFiltersSidebarActive: false,
      modalShow: false,
      dadosItem: {
        nome: '',
        uuid: '',
      },
    }
  },
  computed: {
    isLoad() {
      return this.$store.state['app-propostas'].load
    },
    quantidadeFiltrosAplicados() {
      const filters = this.$store.getters['app-propostas/getFilters']
      return Object.keys(filters).filter(
        (key) =>
          filters[key] !== null &&
          filters[key] !== '' &&
          filters[key] !== 'R$ 0,00',
      ).length
    },
  },
  methods: {
    formatarParaMoedaBrasileira,

    modalExcluir(item) {
      this.dadosItem.nome = item.codigo_proposta
      this.dadosItem.id = item.id
      this.$root.$emit('bv::show::modal', 'modal-confirmacao')
    },
    excluirItem(dados) {
      this.deleteItem(dados)
    },
    tratarEventoAplicarFiltros(filters) {
      this.filters = filters
      this.refetchData()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.per-page-selector {
  width: 90px;
}
</style>
