<template>
  <div>
    <b-row class="mb-2">
      <b-col
          col
          lg="6"
      >
        <h4> Editar Obra : {{ itemData.titulo }}</h4>
      </b-col>
    </b-row>
    <hr>
    <b-form>
      <b-row>
        <b-col
            cols="12"
            sm="6"
        >
          <b-form-group
              label="Título Obra"
              label-for="titulo_obra"
          >
            <b-form-input
                id="titulo_obra"
                v-model="itemData.titulo"
                placeholder="Informe o título da obra"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            sm="6"
        >
          <b-form-group
              label="Escolha o cliente"
              label-for="cliente"
          >
            <select-com-pesquisa
                url="/clientes"
                placeholder="Escolha o cliente"
                :multiple="false"
                :itens-para-edicao="[itemData.cliente]"
                @selecionados="tratarEventoClienteSelecionados"
            />

          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            sm="2"
        >

          <b-form-group
              label="Início da Obra"
              label-for="Inicio Obra"
          >
            <b-form-input
                v-model="itemData.data_inicio"
                type="date"
                placeholder="Informe a data de início da obra"
            />

          </b-form-group>
        </b-col>
        <b-col cols="5">
          <b-form-group
              label="Banner obra [Tamanho mín: 500px x 125px ou na prop. 21:9]"
              label-for="Icone Time"
          >

            <b-form-file
                ref="fileInput"
                label="Imagem"
                accept="image/jpeg, image/png"
                label-for="imagem"
                browse-text="Procurar"
                placeholder="Escolha uma imagem"
                drop-placeholder="Escolha uma imagem"
                @change="handleFileChange"
            />
          </b-form-group>

        </b-col>
        <b-row>
          <b-col
              v-if="!previewImgCropped"
              cols="6"
          >
            <b-form-group
                v-if="itemData.imagem"
                label="Banner Atual"
                label-for="imagem_atual"
                class="pl-2"
            >
              <b-img
                  class="custom-image"
                  :src="itemData.imagem"
                  alt="Banner Atual"
                  height="125"
              />
            </b-form-group>
          </b-col>
          <b-col
              v-if="previewImgCropped"
              cols="6"
          >
            <div class="campo-imagem">
              <p class="pl-1 font-weight-bold">
                Nova imagem:
              </p>
              <b-img
                  class="imagem-cortada pl-1"
                  :src="previewImgCropped"
                  alt="Imagem"
                  height="125"
              />

            </div>
          </b-col>
        </b-row>

      </b-row>
      <h6 class="mt-1 text-primary">
        Participantes:
      </h6>
      <hr>
      <b-row>

        <b-col
            cols="12"
            sm="6"
        >
          <b-form-group
              label="Usuários participantes da obra"
              label-for="usuarios_obras"
          >
            <select-com-pesquisa
                v-model="itemData.users"
                placeholder="Escolha os usuários"
                url="/users"
                :multiple="true"
                :disabled="false"
                :itens-para-edicao="itemData.users || []"
                @selecionados="tratarEventoUsuariosSelecionados"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            sm="6"
        >
          <b-form-group
              label="Times participantes da obra"
              label-for="times_obras"
          >
            <select-com-pesquisa
                v-model="itemData.times"
                placeholder="Escolha os times"
                url="/times"
                :multiple="true"
                :disabled="false"
                valor-name-select="descricao"
                :itens-para-edicao="itemData.times || []"
                @selecionados="tratarEventoTimesSelecionados"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <h6 class="mt-1 text-primary">
        Assessoria:
      </h6>
      <hr>
      <b-row>

        <b-col
            cols="6"
        >
          <b-form-group
              label="Empresa de assessoria"
              label-for="assessoria"
          >
            <select-com-pesquisa
                placeholder="Escolha uma empresa"
                url="/empresas"
                :multiple="false"
                :itens-para-edicao="itemData.assessoria || []"
                @selecionados="tratarEventoAssessoriaSelecionada"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="6"
        >
          <b-form-group
              label="Nome Relatório"
              label-for="nome_relatorio"
          >
            <b-form-input
                v-model="itemData.nome_relatorio"
                type="text"
                placeholder="Informe o nome da obra para o relatório"
            />

          </b-form-group>
        </b-col>

      </b-row>
      <h6 class="mt-1 text-primary">
        Endereço:
      </h6>
      <hr>
      <b-row>
        <b-col
            cols="12"
            sm="6"
        >
          <b-form-group
              label="Logradouro"
              label-for="logradouro_obra"
          >
            <b-form-input
                id="logradouro_obra"
                v-model="itemData.endereco.logradouro"
                placeholder="Informe o logradouro da obra"
            />
          </b-form-group>
        </b-col>
        <b-col
            cols="12"
            sm="2"
        >
          <b-form-group
              label="Número"
              label-for="numero_obra"
          >
            <b-form-input
                id="numero_obra"
                v-model="itemData.endereco.num_endereco"
                placeholder="Informe o número da obra"
            />
          </b-form-group>

        </b-col>
        <b-col
            cols="12"
            sm="4"
        >
          <b-form-group
              label="Complemento"
              label-for="complemento_obra"
          >
            <b-form-input
                id="complemento_obra"
                v-model="itemData.endereco.complemento"
                placeholder="Informe o complemento"
            />
          </b-form-group>

        </b-col>
        <b-col
            cols="12"
            sm="3"
        >
          <b-form-group
              label="Bairro"
              label-for="bairro_obra"
          >
            <b-form-input
                id="complemento_obra"
                v-model="itemData.endereco.bairro"
                placeholder="Informe o bairro"
            />
          </b-form-group>

        </b-col>
        <b-col
            cols="12"
            sm="4"
        >
          <b-form-group
              label="Cidade"
              label-for="cidade_obra"
          >
            <b-form-input
                id="cidade_obra"
                v-model="itemData.endereco.cidade"
                placeholder="Informe a cidade"
            />
          </b-form-group>

        </b-col>
        <b-col
            cols="12"
            sm="1"
        >
          <b-form-group
              label="Estado"
              label-for="estado_obra"
          >
            <b-form-input
                id="estado_obra"
                v-model="itemData.endereco.estado"
                maxlegth="2"
                placeholder="Ex: SC"
            />
          </b-form-group>

        </b-col>
        <b-col
            cols="12"
            sm="4"
        >
          <b-form-group
              label="CEP"
              label-for="cep_obra"
          >
            <b-form-input
                id="cep_obra"
                v-model="itemData.endereco.cep"
                mask="#####-###"
                placeholder="Ex: SC"
            />
          </b-form-group>

        </b-col>
      </b-row>
      <h6 class="mt-1 text-primary">
        Especificações da Obra:
      </h6>
      <hr>
      <b-row>
        <b-col
            cols
            sm="12"
        >
          <select-com-pesquisa
              id="classes"
              v-model="itemData.especificacoes"
              name="nome"
              url="/especificacoes"
              placeholder="Escolha as especificacoes"
              :multiple="true"
              :disabled="false"
              :itens-para-edicao="itemData.especificacoes"
              @selecionados="tratarEventoEspecificacoesSelecionadas"
          />

        </b-col>
      </b-row>

      <h6 class="mt-1 text-primary">
        Localizações:
      </h6>

      <b-row>
        <b-col
            cols
            sm="12"
        >
          <b-form-tags
              v-model="itemData.listaTagsLocalizacoes"
              no-outer-focus
              no-add-on-enter
          >
            <template
                #default="{
                tags,
                inputAttrs,
                inputHandlers,
                addTag,
                removeTag
              }"
            >
              <b-input-group aria-controls="my-custom-tags-list">
                <input
                    v-bind="inputAttrs"
                    placeholder="Nova Localização"
                    class="form-control"

                    v-on="inputHandlers"
                >

                <b-input-group-append>
                  <b-button
                      variant="primary"
                      @click="onOptionClickLocalizacao( inputAttrs,{ addTag })"
                  >
                    <feather-icon icon="PlusIcon"/>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <ul
                  id="my-custom-tags-list"
                  class="list-unstyled d-inline-flex flex-wrap mb-0"
                  aria-live="polite"
                  aria-atomic="false"
                  aria-relevant="additions removals"
              >

                <b-card
                    v-for="tag in itemData.localizacoes"
                    :id="`my-custom-tags-tag_${tag.nome}`"
                    :key="tag.nome"
                    tag="li"
                    class="shadow-none border mt-1 mr-1 mb-0"
                    body-class="py-1 pr-2 text-nowrap"
                >
                  <strong>{{ tag.nome }}</strong>
                  <b-button
                      v-if="tag.registros === 0"
                      variant="link"
                      size="sm"
                      :aria-controls="`my-custom-tags-tag_${tag.nome}`"
                      class="py-0"
                      @click="onRemoveClickLocalizacao(tag, {removeTag})"
                  >
                    <feather-icon
                        class="text-danger"
                        icon="TrashIcon"
                    />
                  </b-button>
                </b-card>
              </ul>
            </template>
          </b-form-tags>
        </b-col>
      </b-row>

    </b-form>
    <!-- Action Buttons -->
    <b-col class="text-right mt-2 pr-0">
      <b-button
          variant="outline-secondary"
          type="button"
          :to="{ name: 'obras' }"
      >
        Voltar
      </b-button>
      <b-button
          type="button"
          variant="success"
          class="mb-1 mb-sm-0 mr-0 ml-sm-1"
          @click="editarItem(itemData)"
      >
        Salvar
      </b-button>
    </b-col>
    <b-modal
        id="modal-cropper"
        title="Cortar imagem"
    >
      <cropper
          class="cropper"
          :src="previewUrl"
          :stencil-props="{
          aspectRatio: 21/9
        }"
          @change="cropPhoto"
      />
      <template v-slot:modal-footer>
        <b-button
            variant="light"
            @click="cancelarCortarImagem()"
        >
          Fechar
        </b-button>
        <b-button @click="atribuirImagemCortada()">
          Cortar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormTags,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BRow,
} from 'bootstrap-vue'
import { converteDataString } from '@core/utils/filter'
import { Cropper } from 'vue-advanced-cropper'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-advanced-cropper/dist/style.css'

export default {

  components: {
    BCard,
    Cropper,
    BFormFile,
    BInputGroupAppend,
    BImg,
    SelectComPesquisa,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTags,
    BInputGroup,
  },

  data() {
    return {
      selectedFile: null,
      value: [],
      previewUrl: '',
      isLoading: true,
      previewImgCropped: null,
      itemData: {
        nome: '',
        definicao: '',
        imagem: '',
        imagemSelected: '',
        users: [],
        cliente: [],
        especificacoes: [],
        assessoria: [],
        endereco: [
          {
            logradouro: '',
            num_endereco: '',
            complemento: '',
            bairro: '',
            cidade: '',
            estado: '',
            cep: '',
          }],
      },

    }
  },
  created() {
    this.getObra()
  },

  methods: {
    onOptionClickEspecificacoes(
        option, { addTag },
    ) {
      if (this.itemData.listaTagsEspecificacoes.includes(option.value)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Especificação já adicionada.',
            icon: 'CheckCircleIcon',
            variant: 'warning',

          },
        })
        return
      }

      const novaOption = {
        nome: option.value,
        registros: 0,
      }

      addTag(novaOption.nome)

      this.itemData.especificacoes.push(novaOption)
      this.search = ''
    },
    onOptionClickLocalizacao(
        option, { addTag },
    ) {
      if (this.itemData.listaTagsLocalizacoes.includes(option.value)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Localização já adicionada.',
            icon: 'CheckCircleIcon',
            variant: 'warning',

          },
        })

        return
      }

      const novaOption = {
        nome: option.value,
        registros: 0,
      }

      addTag(novaOption.nome)

      this.itemData.localizacoes.push(novaOption)
      this.search = ''
    },
    onRemoveClickEspeficicacoes(tag, { removeTag }) {
      this.itemData.especificacoes.splice(this.itemData.especificacoes.indexOf(tag), 1)
      removeTag(tag.nome)
    },
    onRemoveClickLocalizacao(tag, { removeTag }) {
      this.itemData.localizacoes.splice(this.itemData.localizacoes.indexOf(tag), 1)
      removeTag(tag.nome)
    },

    atribuirImagemCortada() {
      this.$bvModal.hide('modal-cropper')
      const file = this.itemData.imagemSelected
      if (file) {
        const reader = new FileReader()
        reader.onload = () => {
          this.previewImgCropped = reader.result
        }
        reader.readAsDataURL(file)
      } else {
        this.previewImgCropped = null
      }
    },
    cancelarCortarImagem() {
      this.$bvModal.hide('modal-cropper')
      this.selectedFile = null
      this.itemData.imagemSelected = null
      this.previewImgCropped = null
      this.$refs.fileInput.reset()
    },
    cropPhoto({ coordinates }) {
      // Cria um novo objeto de imagem

      const img = new Image()
      img.onload = () => {
        // Cria um novo elemento canvas
        const cropCanvas = document.createElement('canvas')
        const ctx = cropCanvas.getContext('2d')

        // Define a largura e a altura do canvas para as dimensões do corte
        const crop = coordinates
        cropCanvas.width = crop.width
        cropCanvas.height = crop.height

        // Desenha a imagem cortada no canvas
        ctx.drawImage(img, crop.left, crop.top, crop.width, crop.height, 0, 0, crop.width, crop.height)

        // Converte a imagem cortada para uma URL de dados
        const dataUrl = cropCanvas.toDataURL('image/png')

        fetch(dataUrl)
            .then(response => response.blob())
            .then(blob => {
              this.itemData.imagemSelected = new File([blob], 'image.png', { type: 'image/png' })
            })
      }
      img.src = this.previewUrl
    },

    handleFileChange(event) {
      const file = event.target.files[0]
      this.selectedFile = file
      this.itemData.imagemSelected = file

      if (file) {
        const reader = new FileReader()
        const img = new Image()
        img.onload = () => {
          if (img.naturalWidth < 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'A largura da imagem deve ser pelo menos 500px',
                icon: 'CheckCircleIcon',
                variant: 'warning',

              },
            })

            this.$refs.fileInput.reset()
            this.selectedFile = null
            return
          }
          this.previewUrl = reader.result
          this.$bvModal.show('modal-cropper')
        }
        reader.onload = () => {
          img.src = reader.result
        }
        reader.readAsDataURL(file)
      } else {
        this.previewUrl = null
      }
    },
    async editarItem() {
      await store.dispatch('app-obras/update', this.itemData)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Obra atualizada com sucesso.',
                icon: 'CheckCircleIcon',
                variant: 'success',

              },
            })
            this.$router.push({ name: 'obras' })
          })
    },
    async getObra() {
      const idTime = this.$router.currentRoute.params.id
      const context = await this
      store.dispatch('app-obras/show', idTime)
          .then(respo => {
            context.itemData = respo.data

            context.itemData.listaTagsLocalizacoes = context.itemData.localizacoes && context.itemData.localizacoes.map(localizacao => localizacao.nome)
            //  context.itemData.listaTagsEspecificacoes = context.itemData.especificacoes ? context.itemData.especificacoes.map(especificacao => especificacao.nome) : []
            context.itemData.data_inicio = converteDataString(context.itemData.data_inicio)
          })
    },

    tratarEventoUsuariosSelecionados(itens) {
      this.itemData.users = itens
    },
    tratarEventoAssessoriaSelecionada(itens) {
      console.log(itens)
      this.itemData.assessoria = itens
    },
    tratarEventoTimesSelecionados(itens) {
      this.itemData.times = itens
    },
    tratarEventoClienteSelecionados(itens) {
      this.itemData.cliente = itens
    },
    tratarEventoEspecificacoesSelecionadas(itens) {
      this.itemData.especificacoes = itens || {}
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.custom-image {
  height: 125px;
  width: auto;
  object-fit: scale-down;
  border: 3px solid #f4f5f7;
}

.imagem-cortada {
  max-width: 500px;
  height: 125px;
}

.cropper {
  min-height: 200px;
  width: 100%;
  max-width: 800px;
  max-height: 500px;
  background: #DDD;
}
</style>
