<template>
  <v-select
      :id="id"
      v-model="dadosItem"
      :options="pagedOptions"
      label="name"
      :name="name"
      :placeholder="placeholder"
      :multiple="multiple"
      :disabled="disabled"
      @search="onSearch"
      @input="opcoesEscolhidas"
      :loading="loading"
  >
    <template v-slot:no-options> Nenhuma opção encontrada</template>
  </v-select>
</template>

<script>
import axios from '@axios'
import vSelect from 'vue-select'

export default {
  name: 'SelectComPesquisa',
  components: {
    vSelect,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    itensParaEdicao: {
      type: Array,
      default: [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    valorNameSelect: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: 'select-com-pesquisa',
    },
    selectFirstOption: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      options: [],
      currentPage: 1,
      pageSize: 10,
      selectedOption: null,
      searchOptions: '',
      totalOptions: 0,
      dadosItem: [],
      edicaoAtualizada: false,
      loading: false,
    }
  },
  computed: {
    pagedOptions() {
      const startIndex = (this.currentPage - 1) * this.pageSize
      const endIndex = startIndex + this.pageSize
      return this.options.slice(startIndex, endIndex)
    },
  },
  watch: {
    currentPagePermissions() {
      this.fetchOptions()
    },
    searchOptions() {
      this.fetchOptions()
    },
    url() {
      this.resetarConteudo()
      this.fetchOptions()
    },
    itensParaEdicao() {
      if (!this.edicaoAtualizada && this.itensParaEdicao.length > 0 && this.itensParaEdicao[0] !== undefined && this.itensParaEdicao[0] !== null) {
        this.edicaoAtualizada = !this.edicaoAtualizada
        this.tratarItensEdicao()
      }
    },
    options(newOptions) {
      if (this.selectFirstOption && newOptions.length > 0) {
        this.dadosItem = [newOptions[0]]
        this.$emit('selecionados', this.dadosItem[0])
      }
    },
  },
  mounted() {
    this.fetchOptions()
    this.tratarItensEdicao()
  },
  methods: {
    onSearch(text) {
      this.searchOptions = text
    },
    opcoesEscolhidas() {
      this.$emit('selecionados', this.dadosItem)
    },
    tratarItensEdicao() {
      if (
          this.itensParaEdicao.length &&
          this.itensParaEdicao[0] !== undefined &&
          this.itensParaEdicao[0] !== null
      ) {
        this.dadosItem = this.itensParaEdicao.map((item) => {
          const nameValue =
              item.descricao || item.nome || item.razao_social || item.name
          return {
            name: nameValue,
            id: item.uuid || item.id,
          }
        })
      }
    },
    async fetchOptions() {
      this.loading = true
      await axios
          .get(this.url, {
            params: {
              q: this.searchOptions,
              perPage: this.perPagePermissions,
              page: this.currentPagePermissions,
              sortBy: this.sortByPermissions,
              sortDesc: this.isSortDirDescPermissions,
            },
          })
          .then((response) => {
            this.options = (response.data.data || response.data).map((item) => {
              let nameValue = null

              if (item.descricao) {
                nameValue = item.descricao
              } else if (item.nome) {
                nameValue = item.nome
              } else if (item.razao_social) {
                nameValue = item.razao_social
              } else if (item.name) {
                nameValue = item.name
              }

              if (
                  this.valorNameSelect &&
                  item[this.valorNameSelect] !== undefined
              ) {
                nameValue = item[this.valorNameSelect]
              }

              return {
                name: nameValue,
                id: item.uuid || item.id,
              }
            })
            this.totalOptions = response.total
          })
          .finally(() => {
            this.loading = false
          })
    },
    resetarConteudo() {
      this.dadosItem = []
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vs__dropdown-option span {
  font-size: 12px;
  z-index: 9999;
}

.vs__dropdown-menu {
  overflow: hidden;
  z-index: 9999;
}

.v-select {
  font-size: 12px;
}
</style>
