<template>
  <div>
    <form-create
        :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
        titulo="Nova Unidade de Medida"
        @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >
      <h5 class="card-title pt-2 pl-2 pb-0 mb-0">
        Lista de Unidade de Medidas
      </h5>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibição</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />

          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Pesquisar..."
              />
              <b-button
                  v-if="$can('salvar','unidades-medida')"
                  variant="success"
                  @click="isAddNewSidebarActive = true"
              >
                <span class="text-nowrap">
                  Adicionar
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refUserListTable"
          class="position-relative font-size-9"
          :items="fetchDados"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nenhum resultado encontrado"
          :sort-desc.sync="isSortDirDesc"
          :busy="isLoad"
          striped
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"/>
            <strong> Carregando...</strong>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(ações)="data">
          <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item v-if="$can('atualizar','unidades-medida')" @click="editarItem(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="$can('excluir','unidades-medida')" @click="modalExcluir(data.item)">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} dos {{ dataMeta.of }} resultados
              encontrados</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="currentPage"
                :total-rows="totalItens"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <modalConfirmacao
        id="modal-confirmacao"
        titulo="Excluir Item"
        :item-id="dadosItem.uuid"
        :item-nome="dadosItem.nome"
        @itemConfirmado="excluirItem"
        @click="modalExcluir(data.item)"
    />
    <b-modal
        id="modal-editar"
        v-model="modalShow"
        :title="`Editar ${dadosItem.nome}`"
        :no-close-on-backdrop="true"
    >
      <b-form-group
          label="Descrição"
          label-for="descrição"
      >
        <b-form-input
            id="nome"
            v-model="dadosItem.nome"
            name="nome"
            autofocus
            trim
            placeholder="Informe o nome"
        />
      </b-form-group>
      <b-form-group
          label="Abreviação"
          label-for="abreviação"
      >
        <b-form-input
            id="abreviacao"
            v-model="dadosItem.abreviacao"
            name="abreviação"
            trim
            placeholder="Informe a abreviação"
        />

      </b-form-group>
      <template #modal-footer>
        <div class="w-100">

          <b-button
              variant="primary"
              size="md"
              class="float-left"
              @click="modalShow = false"
          >
            Fechar
          </b-button>
          <b-button
              variant="success"
              size="md"
              class="float-right"
              @click="salvarItemEditado(dadosItem)"
          >
            Salvar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>

import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BPagination,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
import store from '@/store'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import FormCreate from './FormCreate.vue'
import registroStoreModule from '../unidadeMedidasStoreModule'
import useItemList from './useItemList'
import vSelect from 'vue-select'

export default {
  components: {
    BFormInvalidFeedback,
    BFormGroup,
    SelectComPesquisa,
    FormCreate,
    modalConfirmacao,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect
  },

  setup() {
    const ITEM_STORE_MODULE_NAME = 'app-unidades-medida'

    // Register module
    if (!store.hasModule(ITEM_STORE_MODULE_NAME)) store.registerModule(ITEM_STORE_MODULE_NAME, registroStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ITEM_STORE_MODULE_NAME)) store.unregisterModule(ITEM_STORE_MODULE_NAME)
    })

    const isAddNewSidebarActive = ref(false)

    const {
      fetchDados,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      deleteItem,
      update,
    } = useItemList()

    return {
      isAddNewSidebarActive,
      deleteItem,
      fetchDados,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      update,
    }
  },
  data() {
    return {
      modalShow: false,
      dadosItem: {
        nome: '',
        abreviacao: '',
        uuid: '',
      },
    }
  },
  computed: {
    isLoad() {
      return this.$store.state['app-unidades-medida'].load
    },
  },

  methods: {
    editarItem(item) {
      this.dadosItem = { ...item }
      this.modalShow = true
    },
    salvarItemEditado(item) {
      this.$root.$emit('bv::hide::modal', 'modal-editar')

      const itemEditado = {
        nome: item.nome,
        uuid: item.uuid,
        abreviacao: item.abreviacao,
      }

      this.update(itemEditado)
    },

    modalExcluir(item) {
      this.dadosItem.nome = item.nome
      this.dadosItem.uuid = item.uuid
      this.$root.$emit('bv::show::modal', 'modal-confirmacao')
    },
    excluirItem(dados) {
      this.deleteItem(dados)
    },
  },

}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.per-page-selector {
  width: 90px;
}
</style>
