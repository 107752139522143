export default [
  {
    header: 'COORDENAÇÃO',
    resource: 'usuários',
    action: 'menu',
  },
  {
    title: 'Configurações',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Disciplinas',
        route: 'disciplinas',
        icon: 'SquareIcon',
        resource: 'disciplinas',
        action: 'menu',
      },
      {
        title: 'Subdisciplinas',
        route: 'subdisciplinas',
        icon: 'LayersIcon',
        resource: 'subdisciplinas',
        action: 'menu',
      },
      {
        title: 'Tipo Arquivos',
        route: 'tipo-arquivos',
        icon: 'FileIcon',
        resource: 'formatos',
        action: 'menu',
      },
      {
        title: 'Formatos',
        route: 'projeto-formatos',
        icon: 'MaximizeIcon',
        resource: 'formatos',
        action: 'menu',
      },
      {
        title: 'Tarefas',
        route: 'tarefas',
        icon: 'CheckCircleIcon',
        resource: 'usuários',
        action: 'menu',
      },
      {
        title: 'Etapa de Projeto',
        route: 'etapa-projeto',
        icon: 'ListIcon',
        resource: 'etapaprojeto',
        action: 'menu',
      },
    ],
  },

  {
    title: 'Usuários',
    route: 'usuários',
    icon: 'UsersIcon',
    resource: 'usuários',
    action: 'menu',
  },
]
