<template>
  <section id="inicio">
    <b-row class="match-height">
      <b-col md="12" v-if="$can('dashboard', 'engenharia')">
        <ecommerce-statistics :data="statisticsItems"/>
      </b-col>
      <b-col
          lg="3"
          v-if="$can('dashboard', 'execução')"
      >
        <statistic-card-with-area-chart
            icon="TruckIcon"
            :statistic="dadosDashboard.totalObras"
            statistic-title="Total de Obras"
            :chart-data="dadosDashboard.totalObrasPeriodo"
        />
      </b-col>
      <b-col
          lg="3"
          v-if="$can('dashboard', 'execução')"
      >
        <statistic-card-with-area-chart
            icon="PackageIcon"
            color="success"
            :statistic="dadosDashboard.totalRegistros"
            statistic-title="Total de Registros"
            :chart-data="dadosDashboard.totalRegistrosPeriodo"
        />
      </b-col>
      <b-col
          lg="3"
          v-if="$can('dashboard', 'engenharia')"
      >
        <statistic-card-with-area-chart
            icon="GridIcon"
            color="warning"
            :statistic="dadosDashboard.totalProjetos"
            statistic-title="Total de Projetos"
            :chart-data="dadosDashboard.totalProjetosPeriodo"
        />
      </b-col>
      <b-col
          lg="3"
          v-if="$can('dashboard', 'engenharia')"
      >
        <statistic-card-with-area-chart
            icon="UsersIcon"
            color="success"
            :statistic="dadosDashboard.totalClientes"
            statistic-title="Total de clientes"
            :chart-data="dadosDashboard.totalClientesPeriodo"
        />
      </b-col>

    </b-row>
    <b-row>
      <b-col lg="4" v-if="$can('dashboard', 'engenharia')">
        <ecommerce-goal-overview :data="dadosDashboard.totalClientesComProjetoObj"/>
      </b-col>
      <b-col md="8" v-if="$can('dashboard', 'engenharia')">
        <apex-donut-chart :descricao="'Relação das disciplinas dentro dos projetos'"
                          :titulo="'Disciplinas por projetos'"
                          :series="dadosDashboard.totalProjetosDisciplinas.series || []"
                          :options="dadosDashboard.totalProjetosDisciplinas.chartOptions"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'

import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
import AnalyticsAvgSessions from './AnalyticsAvgSessions.vue'
import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
import AnalyticsTimeline from './AnalyticsTimeline.vue'
import AnalyticsSalesRadarChart from './AnalyticsSalesRadarChart.vue'
import AnalyticsAppDesign from './AnalyticsAppDesign.vue'
import EcommerceStatistics from '@/views/dashboard/ecommerce/EcommerceStatistics.vue'
import store from '@/store'
import analyticsModuleStore from '@/views/dashboard/analytics/analyticsModuleStore'
import moment from 'moment'
import EcommerceGoalOverview from '@/views/dashboard/ecommerce/EcommerceGoalOverview.vue'
import ApexDonutChart from '@/views/charts-and-maps/charts/apex-chart/ApexDonutChart.vue'

export default {
  components: {
    ApexDonutChart,
    EcommerceGoalOverview,
    EcommerceStatistics,
    BRow,
    BCol,
    AnalyticsCongratulation,
    AnalyticsAvgSessions,
    StatisticCardWithAreaChart,
    AnalyticsSupportTracker,
    AnalyticsTimeline,
    AnalyticsSalesRadarChart,
    AnalyticsAppDesign,
    InvoiceList,
  },
  data() {
    return {
      data: {},
      dadosDashboard: {
        totalAtividades: 0,
        totalClientes: 0,
        totalObras: 0,
        totalProjetos: 0,
        clientesComProjetos: 0,
        totalClientesComProjetoObj: {},
        totalProjetosDisciplinas: {
          series: [],
          chartOptions: {}
        },
        totalProjetosPeriodo: [{
          name: '',
          data: [],
        }],
        totalClientesPeriodo: [{
          name: '',
          data: [],
        }],
        totalRegistrosPeriodo: [{
          name: '',
          data: [],
        }],
        totalObrasPeriodo: [{
          name: '',
          data: [],
        }],
        totalRegistros: 0,
      },
      statisticsItems: [],
    }
  },
  created() {
    const ITEM_APP_STORE_MODULE_NAME = 'app-dashboard'

    // Register module
    if (!store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.registerModule(ITEM_APP_STORE_MODULE_NAME, analyticsModuleStore)

    this.iniciarDashbord()

  },
  computed: {
    totalProjetosComputed() {
      return this.totalProjetos
    }
  },
  methods: {
    iniciarDashbord() {
      this.getTotalClientesComProjetos()
      this.getTotalAtividades()
      this.getTotalClientes()
      this.getTotalObras()
      this.getTotalProjetos()
      this.getTotalRegistros()
      this.getTotalProjetosPorDisciplina()
    },
    getTotalAtividades() {
      store.dispatch('app-dashboard/getTotalAtividades', this.mesAtualParaFiltro())
          .then((response) => {
            const dadosAtividade = response.data
            this.statisticsItems = [
              {
                icon: 'InboxIcon',
                color: 'light-primary',
                title: dadosAtividade[0].total,
                subtitle: dadosAtividade[0].tag,
                customClass: 'mb-2 mb-xl-0',
              },
              {
                icon: 'ClockIcon',
                color: 'light-success',
                title: dadosAtividade[1].total,
                subtitle: dadosAtividade[1].tag,
                customClass: 'mb-2 mb-xl-0',
              },
              {
                icon: 'CheckCircleIcon',
                color: 'success',
                title: dadosAtividade[2].total,
                subtitle: dadosAtividade[2].tag,
                customClass: 'mb-2 mb-sm-0',
              },
              {
                icon: 'XCircleIcon',
                color: 'light-danger',
                title: dadosAtividade[3].total,
                subtitle: dadosAtividade[3].tag,
                customClass: '',
              },
            ]
          })
    },
    getTotalClientes() {
      this.getTotalClientesNoPeriodo()
      store.dispatch('app-dashboard/getTotalClientes')
          .then((response) => {
            this.dadosDashboard.totalClientes = response.data
          })
    },
    async getTotalClientesComProjetos() {

      await store.dispatch('app-dashboard/getTotalClientes')
          .then((response) => {
            this.dadosDashboard.totalClientes = response.data

          })
      await store.dispatch('app-dashboard/getTotalClientesComProjetos')
          .then((response) => {
            this.dadosDashboard.totalClientesComProjetos = response.data

          })

      this.dadosDashboard.totalClientesComProjetoObj = {
        completed: this.dadosDashboard.totalClientes,
        inProgress: this.dadosDashboard.totalClientesComProjetos,
        series: [Math.floor((100 * Number(this.dadosDashboard.totalClientesComProjetos)) / Number(this.dadosDashboard.totalClientes))]
      }

    },
    getTotalObras() {
      this.getTotalObrasNoPeriodo()
      store.dispatch('app-dashboard/getTotalObras')
          .then((response) => {
            this.dadosDashboard.totalObras = response.data
          })
    },
    getTotalProjetos() {
      this.getTotalProjetosNoPeriodo()
      store.dispatch('app-dashboard/getTotalProjetos')
          .then((response) => {
            this.dadosDashboard.totalProjetos = response.data

          })
    },

    getTotalClientesNoPeriodo() {
      const periodos = this.criarPeriodosDeBusca()
      this.dadosDashboard.totalClientesPeriodo[0].name = '30/60/90/120 dias'
      Object.entries(periodos)
          .forEach(([key, value]) => {

            store.dispatch('app-dashboard/getTotalClientes', {
              start: value,
              end: moment()
                  .format('YYYY-MM-DD')
            })
                .then((response) => {

                  this.dadosDashboard.totalClientesPeriodo[0].data.push({
                    key: key,
                    value: response.data
                  })

                  // Sort the data after pushing the new element
                  this.dadosDashboard.totalClientesPeriodo[0].data.sort((a, b) => {
                    const daysA = parseInt(a.key.split(' ')[0])
                    const daysB = parseInt(b.key.split(' ')[0])

                    return daysA - daysB
                  })

                })

          })

    },
    getTotalObrasNoPeriodo() {
      const periodos = this.criarPeriodosDeBusca()
      this.dadosDashboard.totalObrasPeriodo[0].name = '30/60/90/120 dias'
      Object.entries(periodos)
          .forEach(([key, value]) => {

            store.dispatch('app-dashboard/getTotalObras', {
              start: value,
              end: moment()
                  .format('YYYY-MM-DD')
            })
                .then((response) => {

                  this.dadosDashboard.totalObrasPeriodo[0].data.push({
                    key: key,
                    value: response.data
                  })

                  // Sort the data after pushing the new element
                  this.dadosDashboard.totalObrasPeriodo[0].data.sort((a, b) => {
                    const daysA = parseInt(a.key.split(' ')[0])
                    const daysB = parseInt(b.key.split(' ')[0])

                    return daysA - daysB
                  })

                })

          })

    },
    getTotalProjetosNoPeriodo() {
      const periodos = this.criarPeriodosDeBusca()
      this.dadosDashboard.totalProjetosPeriodo[0].name = '30/60/90/120 dias'
      Object.entries(periodos)
          .forEach(([key, value]) => {

            store.dispatch('app-dashboard/getTotalProjetos', {
              start: value,
              end: moment()
                  .format('YYYY-MM-DD')
            })
                .then((response) => {

                  this.dadosDashboard.totalProjetosPeriodo[0].data.push({
                    key: key,
                    value: response.data
                  })

                  // Sort the data after pushing the new element
                  this.dadosDashboard.totalProjetosPeriodo[0].data.sort((a, b) => {
                    const daysA = parseInt(a.key.split(' ')[0])
                    const daysB = parseInt(b.key.split(' ')[0])

                    return daysA - daysB
                  })

                })

          })

    },
    getTotalRegistrosNoPeriodo() {
      const periodos = this.criarPeriodosDeBusca()
      this.dadosDashboard.totalRegistrosPeriodo[0].name = '30/60/90/120 dias'
      Object.entries(periodos)
          .forEach(([key, value]) => {

            store.dispatch('app-dashboard/getTotalRegistros', {
              start: value,
              end: moment()
                  .format('YYYY-MM-DD')
            })
                .then((response) => {

                  this.dadosDashboard.totalRegistrosPeriodo[0].data.push({
                    key: key,
                    value: response.data
                  })

                  // Sort the data after pushing the new element
                  this.dadosDashboard.totalRegistrosPeriodo[0].data.sort((a, b) => {
                    const daysA = parseInt(a.key.split(' ')[0])
                    const daysB = parseInt(b.key.split(' ')[0])

                    return daysA - daysB
                  })

                })

          })

    },
    getTotalRegistros() {
      this.getTotalRegistrosNoPeriodo()
      store.dispatch('app-dashboard/getTotalRegistros')
          .then((response) => {
            this.dadosDashboard.totalRegistros = response.data

          })
    },
    teste() {
      return this.totalProjetosComputed
    },
    criarOptionsDonutChart() {
      const context = this
      return {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: ['#ff9f43', '#ffc107', '#73e264', '#20c997', '#17a2b8', '#0087B4', '#7367f0', '#6610f2', '#336195', '#ea5455', '#e83e8c', '#4b4b4b', '#1e1e1e'],
        dataLabels: {
          enabled: true,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '1.5rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1.5rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                },
                total: {
                  show: true,
                  fontSize: '1.5rem',
                  label: '',
                  formatter() {
                    return context.teste()
                  }
                },

              },
            },
          },
        },

      }
    },
    getTotalProjetosPorDisciplina() {

      store.dispatch('app-dashboard/getTotalProjetosPorDisciplina')
          .then((response) => {

            this.dadosDashboard.totalProjetosDisciplinas.series = response.data

            this.dadosDashboard.totalProjetosDisciplinas.series = response.data.map(item => item.total)

            this.dadosDashboard.totalProjetosDisciplinas.chartOptions = this.criarOptionsDonutChart()
            this.dadosDashboard.totalProjetosDisciplinas.chartOptions.labels = response.data.map(item => item.abreviacao || item.nome)
            this.dadosDashboard.totalProjetosDisciplinas.chartOptions.colors = response.data.map(item => item.bg_color || '#73e264')
            this.dadosDashboard.totalProjetosDisciplinas.chartOptions.plotOptions.pie.donut.labels.total = {
              formatter: () => {
                return '00'
              }
            }

          })
    },

    criarPeriodosDeBusca() {
      const today = moment()
      return {
        '30 dias atrás': today.clone()
            .subtract(30, 'days')
            .format('YYYY-MM-DD'),
        '60 dias atrás': today.clone()
            .subtract(60, 'days')
            .format('YYYY-MM-DD'),
        '90 dias atrás': today.clone()
            .subtract(90, 'days')
            .format('YYYY-MM-DD'),
        '120 dias atrás': today.clone()
            .subtract(120, 'days')
            .format('YYYY-MM-DD'),
      }
    },
    mesAtualParaFiltro() {
      const today = moment()
      const firstDay = today.startOf('month')
          .format('YYYY-MM-DD')
      const lastDay = today.endOf('month')
          .format('YYYY-MM-DD')

      return {
        start: firstDay,
        end: lastDay
      }
    },

  },
}
</script>
